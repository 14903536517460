<template>
  <v-container>
    <v-data-table
      :options.sync="options"
      :footer-props="{ itemsPerPageOptions: [10, 25, 50, -1] }"
      :headers="headers"
      :items="bigqueryTables"
      :single-expand="true"
    >
      <template v-slot:top>
        <v-toolbar
          class="mt-4"
          flat
        >
          <BigQueryTableForm
              :bigquery-table-types="bigqueryTableTypes"
              v-on:create="addBigqueryTable"
          />
        </v-toolbar>
      </template>

      <template v-slot:item.type="{ item }">
        <v-row>
          <v-edit-dialog
              @save="save(itemClone, item)"
              @open="itemClone = {...item}"
          >
            {{ item.type }}
            <template v-slot:input>
              <v-select
                  v-model="itemClone.type"
                  :items="bigqueryTableTypes"
                  label="table type"
                  clearable
                  :menu-props="{ closeOnContentClick: true }"
              />
            </template>
          </v-edit-dialog>
        </v-row>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn icon @click="remove(item.id)">
          <v-icon
              color="red"
          >
            mdi-delete
          </v-icon>
        </v-btn>
      </template>

      <template v-slot:item.name="{ item }">
        <v-row>
        <v-edit-dialog
          @save="save(itemClone, item)"
          @open="itemClone = {...item}"
        >
          {{ item.name }}
          <template v-slot:input>
            <v-text-field
              v-model="itemClone.name"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
        </v-row>
      </template>

      <template v-slot:item.caption="{ item }">
        <v-edit-dialog
          @save="save(itemClone, item)"
          @open="itemClone = {...item}"
        >
          <div v-if="item.caption" class="font-weight-black">{{ item.caption }}</div>
          <div v-else class="font-italic font-weight-thin red--text" v-text="'empty'"/>
          <template v-slot:input>
            <v-text-field
              v-model="itemClone.caption"
              label="Edit"
              single-line
              counter
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.description="{ item }">
        <v-edit-dialog
            @save="save(itemClone, item)"
            @open="itemClone = {...item}"
        >
          <div v-if="item.description" class="font-weight-black">
            {{ item.description }}
          </div>
          <div v-else class="font-italic font-weight-thin red--text" v-text="'empty'"/>
          <template v-slot:input>
            <v-text-field
                v-model="itemClone.description"
                label="Edit"
                single-line
                counter
            />
          </template>
        </v-edit-dialog>
      </template>

      <template v-slot:item.is_airplane_default="{ item }">
        <v-switch
            v-model="item.is_airplane_default"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.is_main_default="{ item }">
        <v-switch
            v-model="item.is_main_default"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.is_alternative_default="{ item }">
        <v-switch
            v-model="item.is_alternative_default"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.is_active="{ item }">
        <v-switch
            v-model="item.is_active"
            @change="save(item, item)"
        />
      </template>

      <template v-slot:item.has_sessions="{ item }">
        <v-switch
            v-model="item.has_sessions"
            @change="save(item, item)"
        />
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import BigQueryTableForm from '../components/BigQueryTableForm.vue';

export default {
  name: 'Customer',
  components: {
    BigQueryTableForm,
  },
  data: () => ({
    itemClone: {},
    bigqueryTables: [],
    bigqueryTableTypes: [],
    headers: [
      { text: 'id', value: 'id' },
      { text: 'name', value: 'name' },
      { text: 'caption', value: 'caption' },
      { text: 'description', value: 'description' },
      { text: 'is airplane default', value: 'is_airplane_default' },
      { text: 'is main default', value: 'is_main_default' },
      { text: 'is alternative default', value: 'is_alternative_default' },
      { text: 'is active', value: 'is_active' },
      { text: 'has sessions', value: 'has_sessions' },
      { text: 'type', value: 'type' },
      { text: '', value: 'actions', sortable: false },
    ],
    options: {
      itemsPerPage: 10,
      sortBy: ['id'],
      sortDesc: [false],
      mustSort: true,
      multiSort: false,
    },
  }),

  created() {
    this.$requestHelpers
      .get('api/admin/bigquery/getTableTypes')
      .then((response) => {
        this.bigqueryTableTypes = response.data.result;
      });

    this.$requestHelpers
      .get('api/admin/bigquery')
      .then((response) => {
        this.bigqueryTables = response.data;
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            this.$router.push({ name: 'root' });
          }
        }
      });
  },

  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    addBigqueryTable(customer) {
      this.bigqueryTables.push(customer);
    },

    save(itemClone, item) {
      this.$requestHelpers
        .patch(`api/admin/bigquery/${item.id}`, itemClone)
        .then((response) => {
          if (response.data.success) {
            Object.assign(item, response.data.result);

            this.setSnack({
              color: 'green',
              text: 'saved',
            });
          } else {
            this.setSnack({
              color: 'red',
              text: response.data.message,
            });
          }
        });
    },

    remove(id) {
      const bigqueryTable = this.bigqueryTables.find((el) => el.id === id);
      this.$confirm(`Are you sure you want to delete customer ${bigqueryTable.name}?`).then((res) => {
        if (!res) return;

        this.$requestHelpers
          .delete(`api/admin/bigquery/${id}`)
          .then((response) => {
            if (response.data.success) {
              this.setSnack({
                color: 'green',
                text: 'deleted',
              });
              this.bigqueryTables = this.bigqueryTables.filter((e) => e.id !== id);
            } else {
              this.setSnack({
                color: 'red',
                text: 'error',
              });
            }
          });
      });
    },
  },
};
</script>
