<template>
    <v-dialog v-model="dialog.show" max-width="500px">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="mx-2"
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                fab
                small
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            v-bind="attrs"
                            v-on="on"
                            dark
                        >
                            mdi-plus
                        </v-icon>
                    </template>
                    create new bigquery table
                </v-tooltip>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">new bigquery table</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                  <v-row>
                      <v-col cols="12" sm="12">
                          <v-text-field
                              v-model="bigqueryItem.name"
                              label="name"
                          ></v-text-field>
                      </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="bigqueryItem.caption"
                          label="caption"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="12">
                        <v-text-field
                          v-model="bigqueryItem.description"
                          label="description"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                       <v-select
                        v-model="bigqueryItem.type"
                        :items="bigqueryTableTypes"
                        label="table type"
                        clearable
                        dense
                        chips
                        small-chips
                        :menu-props="{ closeOnContentClick: true }"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="4"
                    >
                      <v-switch
                        v-model="bigqueryItem.isActive"
                        label="is active"
                        class="pa-3"
                      />
                      <v-switch
                          v-model="bigqueryItem.isAirplaneDefault"
                          label="is airplane default"
                          class="pa-3"
                      />
                    </v-col>
                    <v-col
                        cols="4"
                    >
                      <v-switch
                        v-model="bigqueryItem.isMainDefault"
                        label="is main default"
                        class="pa-3"
                      />
                      <v-switch
                        v-model="bigqueryItem.isAlternativeDefault"
                        label="is alternative default"
                        class="pa-3"
                      />
                    </v-col>
                    <v-col
                        cols="4"
                    >
                      <v-switch
                        v-model="bigqueryItem.hasSessions"
                        label="has sessions"
                        class="pa-3"
                      />
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="dialog.show=false"
                  :disabled="dialog.saving"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="dialog.saving"
                    :loading="dialog.saving"
                    @click="save"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'BigQueryTableForm',
  props: {
    bigqueryTableTypes: {
      type: Array,
    },
  },
  data: () => ({
    dialog: {
      saving: false,
      show: false,
    },

    bigqueryItem: {
      id: null,
      name: '',
      caption: '',
      description: '',
      type: '',
      isActive: false,
      isMainDefault: false,
      isAlternativeDefault: false,
      isAirplaneDefault: false,
      hasSessions: false,
    },
  }),
  methods: {
    ...mapActions({
      setSnack: 'snackbar/setState',
    }),

    created() {

    },

    save() {
      this.dialog.saving = false;

      this.$requestHelpers
        .post('api/admin/bigquery',
          {
            name: this.bigqueryItem.name,
            caption: this.bigqueryItem.caption,
            type: this.bigqueryItem.type,
            description: this.bigqueryItem.description,
            is_active: this.bigqueryItem.isActive,
            has_sessions: this.bigqueryItem.hasSessions,
            is_main_default: this.bigqueryItem.isMainDefault,
            is_alternative_default: this.bigqueryItem.isAlternativeDefault,
            is_airplane_default: this.bigqueryItem.isAlternativeDefault,
          }).then((response) => {
          this.$emit('create', response.data.result);

          this.bigqueryItem.name = '';
          this.bigqueryItem.caption = '';
          this.bigqueryItem.description = '';
          this.bigqueryItem.type = '';
          this.bigqueryItem.isActive = false;
          this.bigqueryItem.isMainDefault = false;
          this.bigqueryItem.isAlternativeDefault = false;
          this.bigqueryItem.isAirplaneDefault = false;
          this.bigqueryItem.hasSessions = false;
          this.bigqueryItem.id = null;

          this.dialog.show = false;
        }).finally(() => {
          this.dialog.saving = false;
        });
    },
  },
};
</script>
